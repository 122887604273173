// import React from 'react';
// import "./loading.less";
// const loading = () => {
//     return (
//         <div className="loader">
//             loading...
//         </div>
//     );
// }

// export default loading;

import React, { Component } from 'react'

export default class Loading extends Component {
    render() {
        return (
            <div className="loader">
                {/* loading... */}
            </div>
        )
    }
}

