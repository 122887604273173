import Loadable from "react-loadable";
// loading
import Loading from "@c/loading/loading";

//个人中心
const buyercenter = Loadable({
    loader:()=>import("@p/BuyerCenter"),
    loading:Loading
})
const buypromspecial = Loadable({
    loader:()=>import("@p/Activity/BuyPromSpecial"),
    loading:Loading
})
const buypromnoob = Loadable({
    loader:()=>import("@p/Activity/BuyPromNoob"),
    loading:Loading
})
const buypromspell = Loadable({
    loader:()=>import("@p/Activity/BuyPromSpell"),
    loading:Loading
})
const specialoffer = Loadable({
    loader:()=>import("@p/Activity/SpecialOffer"),
    loading:Loading
})
// 优惠券
// const coupon = Loadable({
//     loader:()=>import("@p/BuyerCenter/Coupon"),
//     loading:Loading
// })

// 二级路由
// const Homec = Loadable({
//     loader:()=>import("@c/homeDemo/homec/homec"),
//     loading:Loading
// })
// const Homec2 = Loadable({
//     loader:()=>import("@c/homeDemo/homec2/homec2"),
//     loading:Loading
// })

//项目具体需要什么额外的信息可配置
//路由路径不要有大写字母、下面仅仅是测试demo
const routes = [
    {
        path: '/buyercenter',
        name:"buyercenter",
        exact: false,
        component: buyercenter
    },
    {
        path: '/buypromspecial',
        name:"buypromspecial",
        exact: false,
        component: buypromspecial
    },
    {
        path: '/buypromnoob',
        name:"buypromnoob",
        exact: false,
        component: buypromnoob
    },
    {
        path: '/buypromspell',
        name:"buypromspell",
        exact: false,
        component: buypromspell
    },
    {
        path: '/specialoffer',
        name:"specialoffer",
        exact: false,
        component: specialoffer
    }
    // {
    //     path: '/HomeDemo',
    //     meta: "HomeDemo",
    //     name:"一级路由home",
    //     component: HomeDemo,
    //     exact: false,
    //     children: [
    //         {
    //             path: '/HomeDemo/Homec',
    //             meta: "HomeDemo/Homec",
    //             id:7879,
    //             name:"二级路由homec",
    //             exact: false,
    //             component: Homec
    //         },
    //         {
    //             path: '/HomeDemo/Homec2',
    //             meta: "HomeDemo/Homec2",
    //             id:123455,
    //             name:"二级路由Homec2",
    //             exact: false,
    //             component: Homec2
    //         }
    //     ]
    // }

]


export default routes;