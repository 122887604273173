import React, { Component } from 'react'

import { Switch,withRouter,Redirect } from "react-router-dom";
import routes from '@router/routeConfig';
import renderRouter from "@router";


class App extends Component{
  render(){
    return (
      <div>
        <Switch>
          {
            renderRouter(routes)
          }
          {/* <Redirect to="/buyercenter"></Redirect> */}
          <Redirect to="/buypromspecial"></Redirect>
      </Switch>
      </div>
    )
  }
}

export default withRouter(App);







