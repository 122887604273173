import {ACITVITYTIMEFALG} from '../constant'

const initState = false;

export default function activityTimeFlag(preState=initState,action){
	const {type,data} = action
	switch (type) {
		case ACITVITYTIMEFALG: 
			return data
		default:
			return preState
	}
}
