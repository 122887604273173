import {ALLINFORMATION} from '../constant'

const initState = [];

export default function allInfo(preState=initState,action){
	const {type,data} = action
	switch (type) {
		case ALLINFORMATION: 
			return data
		default:
			return preState
	}
}
