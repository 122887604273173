/*
    封装路由  swt
    
    注：二级以上路由重定向写children数组里第一个位置！！！
*/
import React,{Fragment} from "react";
// import { Switch, Route,Redirect } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

const renderRouter = (routes) => {
    const eachRoutes = (route) => {
        return (<Route
            key={route.path}
            path={route.path}
            render={props => (
                    <>
                        <route.component {...props} {...route}/>
                        <Switch>
                            {
                                route.children.map((child,i) => {
                                    if (child.children) {
                                        return eachRoutes(child)
                                    }else{
                                        return (
                                            i+1 === route.children.length ? <Fragment key={child.path}>
                                                <Route path={child.path} render={props2=><child.component {...props2} {...child} />} exact={child.exact}/>
                                                {/* <Redirect to={route.children[0].path}></Redirect> */}
                                            </Fragment>:<Route path={child.path} key={child.path} render={props2=><child.component {...props2} {...child} />} exact={child.exact}/>
                                        )
                                    }
                                })
                                
                            }
                        </Switch>
                    </>
                )
            }
        >
            
        </Route>)
    }

    return routes.map((route) => {
        if (route.children) {
            return eachRoutes(route)
        } else {
            return (
                <Route path={route.path} key={route.path} render={props => <route.component {...props} exact={route.exact} {...route}/>} />
            )
        }
    })
}


export default renderRouter;
























