import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";

import store from '@redux/store';
import {Provider} from 'react-redux'

import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

import "@a/css/reset.css";

ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <Provider store={store}>
        <App/>
      </Provider>
    </BrowserRouter>
  </ConfigProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);